u {
  text-decoration-skip-ink: none;
}
.strikediag {
  background: linear-gradient(
    to left top,
    transparent 47.75%,
    currentColor 49.5%,
    currentColor 50.5%,
    transparent 52.25%
  );
}

.table-question th {
  background: rgb(218, 234, 193);
  text-align: center;
}
.table-question th,
td {
  height: 1.5em;
  padding: 0 5px;
}
.table-question tr,
td,
th {
  position: relative;
  border: 1px solid #000;
}
.table-question td {
  border-right: none;
}

.padding-firstline::first-line {
  display: block;
  margin-left: 50px;
  font-weight: bold;
}
