/* yourStyles.css */
.select-size {
  display: inline-block;
  margin: 0;
}
#select-100 .ant-select-selector {
  width: 100px;
  height: 100px;
}

.input {
  /* margin: 2.5px 5px;
  border-bottom-width: 1px; */
}
.checkInput {
  /* color: white; */
  border-radius: 5px;
  /* text-align: center; */
  margin: 2.5px 5px;
  border-bottom-width: 1px;
}
.span {
  margin: 0px 5px;
  background-color: yellow;
}
.b {
  margin: 0px 5px;
}

.video-container {
  overflow: hidden;
  position: relative;
  width: 60%;
}

.video-container::after {
  padding-top: 56.25%;
  display: block;
  content: "";
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.centeredRow {
  justify-content: center;
}

.notes {
  background-attachment: local;
  background-image: linear-gradient(to right, white 10px, transparent 10px),
    linear-gradient(to left, white 10px, transparent 10px),
    repeating-linear-gradient(
      white,
      white 34px,
      #ccc 34px,
      #ccc 36px,
      white 36px
    );
  line-height: 36px;
  padding: 8px 10px;
}

.ant-select-clear {
  background: transparent !important;
}
